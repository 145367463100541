$navbar-breakpoint: 991.98px;
$height-navbar: 75px;
.navbar-dummy {
  height: $height-navbar;
  background-color: $gray-color-3;
}
.navbar {
  z-index: 100;
  color: #333;
  background-color: $gray-color-3;
  width: 100%;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-bottom: 2px solid $primary-color;
  transition: $transition;
  // opacity: 0.95;
  // transform: translateY(-75px);
  position: fixed;
  .navbar-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding: 0rem 12px;
    height: $height-navbar;
  }

  &.navbar-opacity {
    opacity: 0;
    transform: translateY(-75px);
  }

  &.active {
    transform: translateY(0px);
    opacity: 1;
  }

  .z-logo-container {
    display: flex;
    padding-top: 0.4rem;
    cursor: pointer;
  }

  .img-logo {
    height: 60px;
    width: auto;
  }

  .z-nav-pages {
    display: flex;
    padding: 0rem 2rem;
    align-items: center;
  }
  .z-nav-page {
    padding-right: 2rem;
  }
  .page-link {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }
  .z-burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;
    cursor: pointer;
    transition: $transition;
    div {
      width: 25px;
      height: 3px;
      background-color: #fff;
      margin: 2.5px;
      transition: $transition;
    }
    &:hover {
      div {
        background-color: $primary-color;
      }
    }
  }
  .z-toggle {
    div {
      background-color: #fff !important;
    }
    .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
    .line2 {
      opacity: 0;
    }
    .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }

  .navbar-btn {
    display: inline-block;
    width: 140px;
    text-align: center;
  }

  .insta-link {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 30px;
  }

  .instagram-img {
    height: 32px;
    width: auto;
  }

  .dropdown-element {
    position: relative;
    pointer-events: all;
    .dropdown-btn {
      cursor: pointer;
      i {
        margin-left: 0.2rem;
        pointer-events: none;
      }
      &:hover {
        color: $primary-color;
      }
    }
    .ul-dropdown {
      margin-top: 15px;
      position: absolute;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      width: 225px;
      background: $gray-color-3;
      border-radius: 4px;

      border: 1px solid $gray-color-3;
      left: -25px;
      list-style: none;
      transition: all 0.4s ease;
      z-index: -100;
      transform: translateY(-10px);
      pointer-events: none;
      opacity: 0;
    }

    li {
      text-align: start;
      width: 100%;
      padding: 0.75rem 0rem;
      padding-left: 0.6rem;
      transition: $transition;
      border-left: 4px solid $gray-color-3;
      cursor: pointer;
      pointer-events: none;

      a {
        color: #fff;
        font-size: 1.2rem !important;
        pointer-events: none !important;
      }
      &:hover {
        background-color: lighten($gray-color-3, 5%);
        border-left: 4px solid $primary-color;
      }
    }
    &.active {
      ul {
        z-index: 100;
        opacity: 0.99;
        pointer-events: all;
        transform: translateY(0px);
      }
      li {
        pointer-events: all;
        a {
          pointer-events: all !important;
        }
      }
    }
  }

  .small-btn {
    font-size: 1.2rem;
    font-weight: 600;
    transition: $transition;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }
  .small-screen {
    display: none !important;
    i {
      pointer-events: none;
    }
    ul {
      display: none;
      li {
        padding: 0.6rem 0rem;
        a {
          color: #333;
          font-size: 1.2rem;
          font-weight: 600;
          transition: $transition;
          cursor: pointer;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
    &.active {
      ul {
        display: block;
      }
    }
  }

  @media (max-width: $navbar-breakpoint) {
    .z-nav-pages {
      background: $gray-color-2;
      box-shadow: 0 5px 5px -5px lighten(#333, 50%);
      display: flex;
      position: absolute;
      right: 0px;
      top: $height-navbar;
      min-width: 300px;
      max-width: 800px;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      transform: translateX(100%);
      z-index: 10;
      opacity: 0.99;
      padding-top: 0.9rem;
      // SEBASTIAN onsmall screen overflow...
      // max-height: 80vh;
      // overflow-y: scroll;
    }
    .z-nav-page {
      opacity: 0;
      padding: 1.2rem 0rem;
      padding-right: 0rem;
    }
    .page-link {
      color: #333;
      &:hover {
        color: $primary-color;
      }
    }

    .z-burger {
      display: flex;
      z-index: 100;
    }
    .small-screen {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .big-screen {
      display: none !important;
    }
  }
}

@keyframes navSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes navSlideOut {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media (max-width: $navbar-breakpoint) {
  .mobile-overflow-wrapper {
    overflow-x: hidden;
  }
}

.home-me {
  .img-1 {
    width: 100%;
    height: 500px;

    object-fit: scale-down;
    @media (max-width: 767.98px) {
      height: 300px;
    }
  }
  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .one-stichpunkt {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .part-1 {
      width: 50%;
      max-width: 150px;
    }
    .part-2 {
      width: 75%;
    }
    // @media (max-width: 1100px) and (min-width: 767.98px) {
    //   flex-direction: column;
    //   align-items: flex-start;
    //   .part-1 {
    //     padding-bottom: 0.5rem;
    //     margin-right: 0rem;
    //     width: 100%;
    //   }
    //   .part-2 {
    //     width: 100%;
    //   }
    // }
    @media (max-width: 575.98px) {
      flex-direction: column;
      align-items: flex-start;
      .part-1 {
        padding-bottom: 0.5rem;
        margin-right: 0rem;
        width: 100%;
      }
      .part-2 {
        width: 100%;
      }
    }
  }
}

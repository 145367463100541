.page-about-me {
  .img-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0rem 0rem;
    justify-content: center;
  }

  .game-img {
    border-radius: 0.6rem;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 8px 0;
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: cover;
  }
}

.leistungen {
  .top-part {
    max-width: 600px;
    margin: auto;
  }
  .img-container {
    height: 350px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 767.98px) {
      height: 250px;
    }
  }
  .max-desc {
    max-width: 750px;
    margin: auto;
  }
  .img-leistung {
    max-width: 100%;
    height: 250px;
    width: auto;
    object-fit: scale-down;

    @media (max-width: 767.98px) {
      height: 200px;
    }
    @media (max-width: 575.98px) {
      height: 150px;
    }
  }

  .title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding-right: 0px;
    }
    &:after {
      content: '';
      flex: 1;
      margin-left: 1rem;
      height: 2px;
      background-color: #333;
    }
    @media (max-width: 575.98px) {
      font-size: 2.5rem;
    }
  }
}

.kundenstimmen {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 800px;
  padding: 2rem 2%;
  display: flex;
  align-items: center;
  border-top: 2px solid $primary-color;
  border-bottom: 2px solid $primary-color;
  justify-content: center;
  .custom-container {
    position: relative;
    padding: 0 12px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1400px;
    margin: auto;
  }
  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.4);
  }
  @media (max-width: 575.98px) {
    // background: #ffffff;
    .title-text {
      font-size: 2.5rem;
    }
    height: unset;
    padding: 3rem 0rem;
  }
  .one-icon {
    background-color: $gray-color-3;
    padding: 1.5rem 12px;
    transition: all 0.8s ease-out;
    // border: solid 1px #fff;
    margin: 0px 16px;
    opacity: 0.95;
    border-radius: 12px;
    min-height: 250px;
    color: #fff;
    box-shadow: $main-box-shadow;
  }

  .text-part {
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  .bottom-part {
    width: 100%;
  }
  .name-part {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding-right: 0px;
    }
    &:after {
      content: '';
      flex: 1;
      margin-left: 1rem;
      height: 1px;
      background-color: $primary-color;
    }
  }
}

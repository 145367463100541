.tippy-box {
  background: #000000;
  color: #fff;
  border-radius: 4px;
  font-size: 0.9rem;
  padding: 6px 4px;
  max-width: 180px;
  font-size: 0.9rem;
  text-align: center;
}

.theme-border-left {
  border-left: 4px solid $primary-color;
}

.theme-border-left-small {
  border-left: 2px solid $primary-color;
}

.theme-btn {
  border-radius: 24px;
  border: $primary-color 3px solid;
  padding: 8px 0.75rem;
  min-width: 200px;
  display: inline-block;
  font-size: 1.2rem;
  cursor: pointer;
  transition: $transition;
  text-align: center;
  font-weight: 600;
  &:hover {
    background-color: $primary-color;
    color: #fff;
    font-weight: 600;
  }
}

.theme-btn-1 {
  border-radius: 24px;
  border: $primary-color 3px solid;
  padding: 8px 0.75rem;
  min-width: 200px;
  display: inline-block;
  font-size: 1.2rem;
  cursor: pointer;
  transition: $transition;
  background-color: $primary-color;
  text-align: center;
  color: #fff;
  font-weight: 600;
  &:hover {
    background-color: unset;
    color: #000000;
    font-weight: 600;
  }
}

.theme-link {
  color: $primary-color-1;
  transition: $transition;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }
}

.test-section {
  min-height: 100vh;
  background-color: red;
}

.theme-projekt-bilder {
  .one-col {
    padding: 0;
    margin: 0;
    height: 300px;
    @media (max-width: 575.98px) {
      height: 225px;
    }
  }
  .one-projekt-img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    cursor: pointer;
    transition: $transition;
    &:hover {
      -webkit-filter: grayscale(100%) blur(2px);
      filter: grayscale(100%) blur(2px);
    }
  }
}

.theme-fixed-background {
  position: relative;
  // background: url('../img-background/karriere-2-attachment-2.jpg') no-repeat
  //   center center/cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  background-attachment: fixed;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-top: 2px solid $primary-color;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-bottom: 2px solid $primary-color;
  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background-color: rgba(black, 0.1);
  }
  @media (max-width: 767.98px) {
    display: none;
  }
}

.theme-social-links {
  height: 60px;
  width: 60px;
  box-shadow: -7px -7px 17px rgba(255, 255, 255, 0.6), 7px 7px 17px rgba(70, 70, 70, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #fafafa;
  transition: $transition;
  &:hover {
    box-shadow: 0 0 0.1rem #fff, 0 0 0.1rem #fff, 0 0 0.2rem $primary-color, 0 0 0.1rem $primary-color,
      0 0 0.3rem $primary-color, inset 0 0 0.2rem $primary-color;
  }
  .instagram-img {
    height: 32px;
    width: auto;
  }

  .facebook-img {
    height: 40px;
    width: auto;
  }
}

.about-me {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .img-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .img-maren {
    height: 320px;
    width: 320px;
    object-fit: cover;
    border-radius: 50%;
  }
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
  .social-container {
    height: 60px;
    width: 100%;
    max-width: 300px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.right-side {
  .title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding-right: 0px;
    }
    &:after {
      content: '';
      flex: 1;
      margin-left: 1rem;
      height: 2px;
      background-color: $gray-color-2;
    }
  }
  .box-owner {
    background-color: #fff;
    padding: 12px 1rem;
    box-shadow: $main-box-shadow;
    border-radius: 0.6rem;
  }

  .owner-img {
    height: auto;
    width: 100%;
    max-width: 200px;
    // border-radius: 12px;
    // border-style: none;
    // box-shadow: $main-box-shadow;
  }

  .social-container {
    height: 80px;
    width: 100%;
    max-width: 400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  margin: auto;
}

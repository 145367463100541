.footer {
  background-color: $gray-color-3;
  padding: 1.5rem 2% 2rem 2%;
  .row {
    @media (max-width: 991.98px) {
      max-width: 600px;
      margin-right: auto;
    }
  }
  .img-part {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @media (max-width: 991.98px) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }
    @media (max-width: 575.98px) {
      align-items: flex-start;
      max-width: 300px;
      margin-right: auto;
    }
  }
  .img-container {
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
  }
  .img-logo {
    height: 75px;
    width: auto;
  }

  ul {
    color: #ffffff;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      padding-bottom: 0.35rem;
      a {
        display: block;
        color: #ffffff;
        font-size: 1.2rem;
        transition: all 0.5s ease-out;
        margin: 0;
        padding: 0;
        &:hover {
          color: $primary-color;
        }
      }
      @media (max-width: 575.98px) {
        padding-bottom: 1.5rem;
      }
    }
  }
  .content-part {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    @media (max-width: 991.98px) {
      align-items: flex-start;
    }
    @media (max-width: 575.98px) {
      align-items: flex-start;
      max-width: 300px;
      margin-right: auto;
    }
    .title {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 600;
      @media (max-width: 767.98px) {
        padding-top: 0.6rem;
      }
    }
  }
  .copyright {
    font-size: 1.5rem;
    font-weight: 600;
    color: $primary-color;
  }
}

.projekt-details {
  .title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding-right: 0px;
    }
    &:after {
      content: '';
      flex: 1;
      margin-left: 1rem;
      height: 2px;
      background-color: #333;
    }
    @media (max-width: 575.98px) {
      font-size: 2.5rem;
    }
  }
  .signature-img {
    width: 200px;
    height: auto;
  }
  .massiv-img {
    height: 120px;
    width: auto;
    max-width: 300px;
    object-fit: scale-down;
  }
  .one-fact {
    display: flex;
    align-items: flex-start;
  }
  .fact-type {
    min-width: 100px;
  }
  .img-contianer-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767.98px) {
      flex-direction: column;
      div {
        padding-bottom: 1rem;
      }
    }
    &.img-contianer-1__massiv {
      @media (max-width: 1000.98px) {
        flex-direction: column;
        div {
          padding-bottom: 1rem;
        }
      }
    }
  }
}

.home-kontakt {
  position: relative;
  border-top: 2px solid $primary-color;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 6rem 12px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 575.98px) {
    // background: #ffffff;
    height: unset;
    padding: 3rem 0rem;
  }
  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.75);
  }

  .title {
    font-size: 40px;
    @media (max-width: 575.98px) {
      font-size: 30px;
    }
  }

  .content-container {
    position: relative;
    max-width: 750px;
    padding: 0 12px;
    @media (max-width: 1199.98px) {
      margin: auto;
    }
    @media (max-width: 767.98px) {
      max-width: 600px;
      flex-direction: column;
      align-items: center;
    }
  }
  .input-container {
    input,
    textarea {
      width: 100%;
      padding: 0.9rem 0.75rem;
      font-size: 1.25rem;
      border-radius: 12px;
      transition: all 0.4s ease-in;
      &.error {
        border: 3px solid $red-color;
        border-bottom: 3px solid $red-color;
      }
    }
    textarea {
      min-height: 200px;
    }
  }
  .law-container {
    display: flex;

    color: #ffffff;

    small {
      color: $red-color;
      opacity: 0;
    }
    &.error {
      small {
        opacity: 1;
        display: block;
      }
    }
  }
  .send-btn {
    font-size: 1.5rem;
    font-weight: 600;
  }
  #main_error {
    color: $red-color;
  }
}

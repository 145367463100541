.image-gallery {
  min-height: 120vh;
  .flex-image-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 4px;
  }
  .column {
    flex: 33.3%;
    max-width: 33.3%;
    padding: 0 4px;
  }

  .one-img {
    // margin-top: 8px;
    width: 100%;
    cursor: pointer;
    transition: $transition;
    &:hover {
      // -webkit-filter: grayscale(100%) blur(2px);
      // filter: grayscale(100%) blur(2px);
      -webkit-filter: grayscale(80%);
      filter: grayscale(80%);
    }
  }

  @media screen and (max-width: 800px) {
    .column {
      flex: 49%;
      max-width: 49%;
    }
    .column-3 {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
    .column-2 {
      display: none;
    }
  }
}

.faq {
  padding-top: 3rem;
  padding-bottom: 8rem;
  min-height: 100vh;

  .title-container {
    background-color: $gray-color-3;
    color: #fff;
    border-top-left-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
    padding: 0.5rem 12px;
    border-bottom: 4px $primary-color solid;
  }
  .faq-content-container {
    background: #fff;
    padding: 0.75rem 12px;
    margin-bottom: 3rem;
    box-shadow: $main-box-shadow;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
}

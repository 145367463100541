.blog-unter {
  .blog-unter-container {
    box-shadow: $main-box-shadow;
    border-radius: 0.6rem;
    background-color: #fff;
  }
  .blog-unter-img-container {
    position: relative;
  }

  .nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: $transition;
    font-size: 1.8rem;
    color: $gray-color-2;
    &:hover {
      color: $primary-color;
    }
  }
  .nav-btn-right {
    right: 0%;
  }
  .blog-unter-img {
    cursor: pointer;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-bottom: 3px solid $primary-color;
  }

  .small-foto-container {
    height: 120px;
    width: 120px;
    margin: 0rem 0.2rem;
    display: inline-block;
  }
  .blog-unter-small-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  .nav-posts {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 3px solid $gray-color-2;
  }
  .nav-next {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    padding: 1rem;
    padding-left: 1rem;
    border-right: 2px solid $gray-color-2;
    width: 50%;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }
  .nav-prev {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    padding-right: 1rem;
    width: 50%;
    cursor: pointer;
    transition: $transition;
    &:hover {
      color: $primary-color;
    }
  }

  .nav-title {
    color: $primary-color-1;
  }

  .title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding-right: 0px;
    }
    &:after {
      content: "";
      flex: 1;
      margin-left: 1rem;
      height: 2px;
      background-color: $gray-color-2;
    }
  }

  .one-blog-prevs {
    background-color: #fff;
    padding: 12px 1rem;
    box-shadow: $main-box-shadow;
    border-radius: 0.6rem;
  }
  .one-blog-prev-img {
    height: auto;
    width: 100%;

    border-radius: 12px;
    border-style: none;
    box-shadow: $main-box-shadow;
  }

  .date-icon {
    color: $primary-color;
  }
  .one-blog-prev {
    border-bottom: 3px $gray-color-2 solid;
    margin-bottom: 2rem;
  }
}

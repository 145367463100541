.kontakt-leiste {
  padding: 1rem 12px;
  background-color: #fff;

  margin: auto;
  box-shadow: $main-box-shadow;
  border-radius: 0.6rem;
  // max-width: 750px;
  .contact-title {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      padding-right: 0px;
    }
    &:after {
      content: '';
      flex: 1;
      margin-left: 1rem;
      height: 2px;
      background-color: $gray-color-2;
    }
  }

  .sub-title {
    .role {
      font-size: 1rem;
      display: block;
    }
    .bene {
      color: $primary-color-1;
      display: block;
    }
  }
  table {
    td {
      padding-bottom: 0.75rem;

      transition: $transition;
      font-size: 1.1rem;
    }
    a {
      color: #333;
      transition: $transition;
      font-size: 1.1rem;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .link-desc {
    display: block;
    color: #333;
    transition: $transition;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      color: $primary-color;
    }
  }

  .img-col {
    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .social-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 225px;
  }
  .img-maren {
    height: 200px;
    object-fit: scale-down;
    width: 100%;
    padding: 12px 12px;
  }

  .icon-td {
    padding-right: 0.575rem;
  }
  .wicon {
    img {
      width: 45px;
      height: auto;
      transform: translateY(4px);
      pointer-events: none;
    }
  }
  .mailicon {
    img {
      width: 40px;
      height: auto;
      pointer-events: none;
    }
  }
  .barcode-col {
    display: flex;
    align-self: flex-end;
    padding-bottom: 1.2rem;
    justify-content: center;
    img {
      width: 150px;
      height: auto;
    }
  }
  @media (max-width: 991.98px) {
    .content-col {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2rem;
    }
    .barcode-col {
      justify-content: center;
    }

    .img-col {
      .img-container {
        img {
          width: 300px;
          height: auto;
        }
      }
    }
    .contact-title {
      text-align: center;
    }
  }
  @media (max-width: 767.98px) {
    .barcode-col {
      align-self: flex-end;
      justify-content: center;
      max-width: 200px;
      margin: auto;
      padding-top: 1rem;
      padding-bottom: 1.2rem;
      img {
        width: 150px;
        height: auto;
      }
    }
  }
  @media (max-width: 575.98px) {
    .contact-title {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 460.98px) {
    .barcode-col {
      display: none;
    }
  }
}

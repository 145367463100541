//  Showcase

$showcase-height: 300px;
$showcase-height-2: 500px;
$showcase-height-3: 400px;
$showcase-height-4: 380px;
$showcase-unterseite: 400px;
.showcase {
  width: 100%;
  height: $showcase-height;
  position: relative;

  text-align: center;
  border-bottom: 5px solid $primary-color;
  box-shadow: 0 0 2px 2px $primary-color;

  .overlay {
    width: 100%;
    height: $showcase-height;
    background-color: rgba(#333, 0.65);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: $showcase-height;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    // opacity: 0.9;
  }

  h1 {
    padding: 0 12px;
    // margin-top: 80px;
    color: #ffffff;

    text-align: center;

    .h1-fett {
      font-size: 3.2rem;
      font-weight: 600;
    }
    .h1-klein {
      margin-top: 1rem;
      display: block;
      font-size: 2rem;
    }
    @media (max-width: 991.98px) {
      .h1-fett {
        font-size: 2.5rem;
      }
    }
    @media (max-width: 767.98px) {
      .h1-fett {
        font-size: 2.2rem;
      }
      .h1-klein {
        font-size: 1.2rem;
      }
    }
    @media (max-width: 575.98px) {
      .h1-fett {
        font-size: 2rem;
      }
      .h1-klein {
        font-size: 1.2rem;
      }
    }
  }

  a.btn {
    padding: 1rem 2rem;
    font-size: 1.3rem;
    @media (max-width: 767.98px) {
      padding: 0.75rem 1.5rem;
      font-size: 1.2rem;
    }
  }
}

$primary-color: #cfe107;
$primary-color-1: #a5b405;

$secondary-color: #f8f9fa;
$third-color: #ca020b;
$fourth-color: rgb(196, 113, 16);

$navbar-color: $primary-color;
$button-color: $primary-color-1;
$border-color: lighten(#333, 60%);

// $background-color: #f5f5f5;
$background-color: #fdf5e8;

$dark-color: #0a1726;
$gray-color: #808080;
$light-color: #f4f4f4;
$red-color: #dc3545;
$blue-color: #1a73e8;
$green-color: #28a745;
$orange-color: #ffa500;

$primary-overlay: lighten($primary-color, 20%);

$gray-color-1: #fbfbfb;
$gray-color-2: #ececec;
$gray-color-3: #404040;
// $gray-whatsapp: #fafafa;
// $primary-color-15: rgba($primary-color, 0.15);

$background-color-2: rgb(255, 253, 234); // #FFFDEA

$primary-color-hover: darken($primary-color, 10%);

// @import url('https://fonts.googleapis.com/css2?family=Kalam&family=Prata&display=swap');
@font-face {
  font-family: 'Kalam';
  src: url('/src/assets/fonts/Kalam/Kalam-Regular.ttf') format('truetype');
  // src: url("/src/assets/fonts/montserrat/Montserrat-ExtraLight.otf") format("otf"),
  //   url("/src/assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Prata';
  src: url('/src/assets/fonts/Prata/Prata-Regular.ttf') format('truetype');
  // src: url("/src/assets/fonts/montserrat/Montserrat-ExtraLight.otf") format("otf"),
  //   url("/src/assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
}
$font-family: 'Kalam', Arial, Helvetica, sans-serif;
$font-family-1: 'Prata', sans-serif;
$transition: all 0.4s ease-out;
$main-box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 8px 0;
// table

$card-green: $primary-color;

$background-color-1: $background-color;
$background-color-2: $background-color;
$background-color-3: $background-color;

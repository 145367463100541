.background-11 {
  background-image: radial-gradient(
      circle at 69% 86%,
      rgba(165, 165, 165, 0.06) 0%,
      rgba(165, 165, 165, 0.06) 25%,
      rgba(193, 193, 193, 0.06) 25%,
      rgba(193, 193, 193, 0.06) 50%,
      rgba(221, 221, 221, 0.06) 50%,
      rgba(221, 221, 221, 0.06) 75%,
      rgba(249, 249, 249, 0.06) 75%,
      rgba(249, 249, 249, 0.06) 100%
    ),
    radial-gradient(
      circle at 49% 76%,
      rgba(129, 129, 129, 0.06) 0%,
      rgba(129, 129, 129, 0.06) 25%,
      rgba(164, 164, 164, 0.06) 25%,
      rgba(164, 164, 164, 0.06) 50%,
      rgba(200, 200, 200, 0.06) 50%,
      rgba(200, 200, 200, 0.06) 75%,
      rgba(235, 235, 235, 0.06) 75%,
      rgba(235, 235, 235, 0.06) 100%
    ),
    radial-gradient(
      circle at 22% 64%,
      rgba(173, 173, 173, 0.06) 0%,
      rgba(173, 173, 173, 0.06) 25%,
      rgba(119, 119, 119, 0.06) 25%,
      rgba(119, 119, 119, 0.06) 50%,
      rgba(64, 64, 64, 0.06) 50%,
      rgba(64, 64, 64, 0.06) 75%,
      rgba(10, 10, 10, 0.06) 75%,
      rgba(10, 10, 10, 0.06) 100%
    ),
    linear-gradient(313deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.background-12 {
  background-image: linear-gradient(
      135deg,
      rgba(186, 186, 186, 0.03) 0%,
      rgba(186, 186, 186, 0.03) 10%,
      rgba(133, 133, 133, 0.03) 10%,
      rgba(133, 133, 133, 0.03) 14%,
      rgba(38, 38, 38, 0.03) 14%,
      rgba(38, 38, 38, 0.03) 17%,
      rgba(93, 93, 93, 0.03) 17%,
      rgba(93, 93, 93, 0.03) 25%,
      rgba(80, 80, 80, 0.03) 25%,
      rgba(80, 80, 80, 0.03) 45%,
      rgba(239, 239, 239, 0.03) 45%,
      rgba(239, 239, 239, 0.03) 100%
    ),
    linear-gradient(
      135deg,
      rgba(236, 236, 236, 0.03) 0%,
      rgba(236, 236, 236, 0.03) 47%,
      rgba(182, 182, 182, 0.03) 47%,
      rgba(182, 182, 182, 0.03) 63%,
      rgba(223, 223, 223, 0.03) 63%,
      rgba(223, 223, 223, 0.03) 81%,
      rgba(86, 86, 86, 0.03) 81%,
      rgba(86, 86, 86, 0.03) 89%,
      rgba(23, 23, 23, 0.03) 89%,
      rgba(23, 23, 23, 0.03) 90%,
      rgba(226, 226, 226, 0.03) 90%,
      rgba(226, 226, 226, 0.03) 100%
    ),
    linear-gradient(
      45deg,
      rgba(52, 52, 52, 0.03) 0%,
      rgba(52, 52, 52, 0.03) 31%,
      rgba(246, 246, 246, 0.03) 31%,
      rgba(246, 246, 246, 0.03) 63%,
      rgba(188, 188, 188, 0.03) 63%,
      rgba(188, 188, 188, 0.03) 71%,
      rgba(15, 15, 15, 0.03) 71%,
      rgba(15, 15, 15, 0.03) 87%,
      rgba(127, 127, 127, 0.03) 87%,
      rgba(127, 127, 127, 0.03) 93%,
      rgba(234, 234, 234, 0.03) 93%,
      rgba(234, 234, 234, 0.03) 100%
    ),
    linear-gradient(90deg, #ffffff, #ffffff);
}

.background-13 {
  background-image: radial-gradient(
      circle at center center,
      rgba(217, 217, 217, 0.05) 0%,
      rgba(217, 217, 217, 0.05) 15%,
      rgba(197, 197, 197, 0.05) 15%,
      rgba(197, 197, 197, 0.05) 34%,
      rgba(178, 178, 178, 0.05) 34%,
      rgba(178, 178, 178, 0.05) 51%,
      rgba(237, 237, 237, 0.05) 51%,
      rgba(237, 237, 237, 0.05) 75%,
      rgba(138, 138, 138, 0.05) 75%,
      rgba(138, 138, 138, 0.05) 89%,
      rgba(158, 158, 158, 0.05) 89%,
      rgba(158, 158, 158, 0.05) 100%
    ),
    radial-gradient(
      circle at center center,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 6%,
      rgb(255, 255, 255) 6%,
      rgb(255, 255, 255) 12%,
      rgb(255, 255, 255) 12%,
      rgb(255, 255, 255) 31%,
      rgb(255, 255, 255) 31%,
      rgb(255, 255, 255) 92%,
      rgb(255, 255, 255) 92%,
      rgb(255, 255, 255) 97%,
      rgb(255, 255, 255) 97%,
      rgb(255, 255, 255) 100%
    );
  background-size: 42px 42px;
}

.background-14 {
  background-image: linear-gradient(
      112.5deg,
      rgba(232, 232, 232, 0.03) 0%,
      rgba(232, 232, 232, 0.03) 2%,
      rgba(231, 231, 231, 0.03) 2%,
      rgba(231, 231, 231, 0.03) 4%,
      rgba(231, 231, 231, 0.03) 4%,
      rgba(231, 231, 231, 0.03) 11%,
      rgba(2, 2, 2, 0.03) 11%,
      rgba(2, 2, 2, 0.03) 67%,
      rgba(231, 231, 231, 0.03) 67%,
      rgba(231, 231, 231, 0.03) 90%,
      rgba(111, 111, 111, 0.03) 90%,
      rgba(111, 111, 111, 0.03) 100%
    ),
    linear-gradient(
      157.5deg,
      rgba(210, 210, 210, 0.03) 0%,
      rgba(210, 210, 210, 0.03) 17%,
      rgba(254, 254, 254, 0.03) 17%,
      rgba(254, 254, 254, 0.03) 18%,
      rgba(96, 96, 96, 0.03) 18%,
      rgba(96, 96, 96, 0.03) 44%,
      rgba(159, 159, 159, 0.03) 44%,
      rgba(159, 159, 159, 0.03) 70%,
      rgba(24, 24, 24, 0.03) 70%,
      rgba(24, 24, 24, 0.03) 82%,
      rgba(16, 16, 16, 0.03) 82%,
      rgba(16, 16, 16, 0.03) 100%
    ),
    linear-gradient(
      22.5deg,
      rgba(47, 47, 47, 0.03) 0%,
      rgba(47, 47, 47, 0.03) 32%,
      rgba(124, 124, 124, 0.03) 32%,
      rgba(124, 124, 124, 0.03) 40%,
      rgba(200, 200, 200, 0.03) 40%,
      rgba(200, 200, 200, 0.03) 42%,
      rgba(16, 16, 16, 0.03) 42%,
      rgba(16, 16, 16, 0.03) 64%,
      rgba(243, 243, 243, 0.03) 64%,
      rgba(243, 243, 243, 0.03) 94%,
      rgba(93, 93, 93, 0.03) 94%,
      rgba(93, 93, 93, 0.03) 100%
    ),
    linear-gradient(90deg, #ffffff, #ffffff);
}

.showcase-startseite {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 2rem 12px;
  display: flex;

  align-items: center;
  justify-content: center;

  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  border-bottom: 2px solid $primary-color;

  .custom-container {
    position: relative;
    padding: 0 12px;
    width: 100%;
    max-width: 1400px;
    // margin: auto;
  }
  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.2);
  }

  .logo-img {
    width: 100%;
    max-width: 200px;
    height: auto;
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 450px;
    margin: auto;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
  .extra-btn,
  .extra-btn-1 {
    color: #fff;
    font-weight: 600;
    font-size: 22px;
  }
  // .content-part {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-around;
  // }
  @media (max-width: 991.98px) {
    .title {
      font-size: 3rem;
    }
  }
  @media (max-width: 575.98px) {
    .title {
      font-size: 2.5rem;
    }
  }

  @media (max-width: 1199.98px) {
    justify-content: center;
    align-items: flex-end;
  }

  @media (max-width: 767.98px) {
    justify-content: flex-end;
    align-items: flex-end;
  }

  @media (max-width: 575.98px) {
    height: unset;
    min-height: 600px;
    padding: 3rem 0rem;
  }
}

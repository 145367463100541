.projekte-startseite {
  .projekt-img-container {
    height: 350px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: 767.98px) {
      height: 200px;
    }
  }
  .projekt-img {
    border-radius: 0.6rem;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 8px 0;
    width: 400px;
    height: 300px;
    text-align: center;
    object-fit: cover;

    @media (max-width: 767.98px) {
      width: 280px;
      height: 180px;
    }
  }
  .projekt-link {
    &:hover {
      text-decoration: underline;
      color: $primary-color-1;
    }
  }
}

.leistungen-unter {
  .img-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .img-leistung {
    max-width: 100%;
    height: 250px;
    width: auto;
    object-fit: scale-down;

    @media (max-width: 767.98px) {
      height: 200px;
    }
    @media (max-width: 575.98px) {
      height: 150px;
    }
  }
}

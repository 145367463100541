.law {
  padding-bottom: 8rem;
  padding-top: 4rem;

  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
  h1 {
    // text-align: center;
    color: $primary-color;
    font-size: 3rem;
    font-weight: 600;
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    padding-top: 1rem;
  }

  p {
    margin: 0.2rem 0rem;
    padding: 0rem 0rem;
  }
  h3 {
    padding-top: 0.6rem;
    color: $primary-color;
  }
  a {
    text-decoration: underline;
  }
  hr {
    width: 60px;
    color: $primary-color;
    height: 2px;
    background-color: $primary-color;
    margin-right: auto;
    border: none;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
  }

  li,
  dd {
    padding-bottom: 0.2rem;
  }

  .right-col {
    padding-bottom: 2rem;
    .content {
      background-color: #fff;
      border-radius: 4px;
      padding: 1rem 12px;

      box-shadow: $main-box-shadow;
      ul {
        font-size: 0.9rem;
        color: $gray-color;
        .z-bold {
          color: #000000;
        }
      }
    }
  }
  .left-col {
    .content {
      border-radius: 4px;
      padding: 1rem 12px;
    }
  }
}

.blog {
  min-height: 100vh;
  .one-blog {
    background: #fff;
    margin-bottom: 3rem;
    box-shadow: $main-box-shadow;
    border-radius: 0.6rem;
  }
  .blog-img {
    cursor: pointer;
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-bottom: 3px solid $primary-color;
    -webkit-filter: grayscale(0) blur(0);
    filter: grayscale(0) blur(0);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    &:hover {
      -webkit-filter: grayscale(100%) blur(2px);
      filter: grayscale(100%) blur(2px);
    }
  }
  .date-icon {
    color: $primary-color;
  }
  .title {
    color: #333;
    position: relative;
    cursor: pointer;
    z-index: 1;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    & > span.bg {
      background: $primary-color;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      transition: all 0.9s ease-in-out;
    }
    &:hover {
      color: black;
    }

    &:hover > span.bg {
      transition: all 0.9s ease-in-out;
    }
    & > .bg {
      left: 0;
      bottom: 0;
      transform: translateX(-100%);
    }

    &:hover > .bg {
      transform: translateX(0);
    }
  }
}
